h1,
h2,
h3,
h4,
h5,
h6 {
  color: $body-color;
  font-family: $font-family;
  font-weight: 700;
}

h1 {
  font-size: $h1-font-size;
  line-height: $h1-line-height;
}

h2 {
  font-size: $h2-font-size;
  line-height: $h2-line-height;
}

h3 {
  font-size: $h3-font-size;
  line-height: $h3-line-height;
}

h4 {
  font-size: $h4-font-size;
  line-height: $h4-line-height;
}

h5 {
  font-size: $h5-font-size;
  line-height: $h5-line-height;
}

h6 {
  font-size: $h6-font-size;
  line-height: $h6-line-height;
}

.ln {
  &-xlg {
    line-height: $line-height-xlg;
  }
  &-xsm {
    line-height: $line-height-xsm;
  }
  &-xxsm {
    line-height: $line-height-xxsm;
  }
}

a {
  text-decoration: none;
}

.dark {
  color: $body-color-dark;
  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    color: $text-icon-light;
  }
}

.font-weight-medium {
  font-weight: 500  !important;
}

.text-primary {
  color: $primary;
}

.dark {
  .text-primary {
    color: $primary900;
  }
  .text-dark {
    color: $text-icon-light !important;
  }
}
* {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

a.link-dark {
  color: $primary;
}

a.dark {
  color: $primary900;
  text-decoration: underline;
  &:hover {
    color: lighten($primary900, 10);
  }
}

.text-small {
  font-size: 0.75rem !important;
}

.text-white {
  color: white;
}

.text {
  &__link {
    color: $primary;
    &:hover {
      color: lighten($primary, 10);
    }
  }
}

.text-no-decoration {
  text-decoration: none;
  &:hover {
    text-decoration: none;
  }
}

.large-para {
  font-size: 1.25rem;
}

.bold-link {
  font-weight: bold !important;
}

.feature {
  &--off {
    color: $danger;
  }
  &--on {
    color: $primary;
  }
}
