.icon {
    &--green {
        color: $header-color;
    }
    &--tooltip {
        font-size: $font-size-base;
    }
    &--new-tooltip {
        font-size: 19px;
    }
}
