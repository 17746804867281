@import "../variables";

.flag-different {
  .rc-switch {
    background-color: $success !important;
    border-color: $success !important;
    &::after{
      background-color: white;
    }
  }
  .feature-value {
    .feature-value-container {
      display: inline-block;
      .quot,.feature-value {
        color: $success !important;
      }
    }
  }
}
