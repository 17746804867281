@import '../variables';
.code-help {
  .tabs {
    display: flex;
  }
  .tabs-content {
    flex: 1;
    width: 100%;
    code {
      margin: 20px;
      margin-left: 0;
      margin-right: 0;
      max-width: 100%;
    }
  }
  .tabs-nav {
    display: none;
  }
  .tab-line {
    display: none;
  }
  .hljs-container {
    code {

      padding-top: 80px;
    }
    .react-select {
      position: absolute;
      top:16px;
      left:16px;
    }
  }
}
.dark {
  .tabs {
    .tabs-nav {
      background-color: transparent;
      button,
      button.btn,
      button.btn-primary {
        color: $text-icon-light;
        &.btn:active {
          color: $text-icon-light;
        }
      }
    }
    hr {
      border-color: $white-alpha-8;
    }
    & .pill {
      background: $bg-dark300;
    }
  }
}
.tabs-nav {
  position: relative;
  display: flex;
  flex-direction: row;
  top: 2px;
  button,
  button.btn,
  button.btn-primary {
    border-radius: 0;
    box-shadow: none;
    flex: 1;
    background-color: transparent;
    color: $body-color;
    &:hover {
      background-color: transparent;
      box-shadow: none;
    }
    &.btn:active {
      box-shadow: none;
      background: transparent;
      color: $body-color;
    }
    min-height: 34px;
    font-weight: $tab-btn-font-weight;
    line-height: $tab-btn-line-height;
    padding: 0;
    &.tab-active {
      font-weight: $tab-btn-active-font-weight;
      background: transparent;
      border-bottom: 2px solid $primary;
    }
    .counter-value {
      font-size: $tab-btn-counter-value-font-size;
      background-color: $tab-btn-counter-value-bg;
      line-height: $line-height-xxsm;
      display: flex;
      width: $tab-btn-counter-value-width;
      height: $tab-btn-counter-value-width;
      border-radius: 50%;
      color: $text-icon-light;
      align-items: center;
      justify-content: center;
      font-weight: $tab-btn-font-weight;
    }
  }
}

.tabs {
  & .pill {
    background-color:$pill-bg;
    border-radius: $border-radius-xlg;
    display: inline-block;
    button,
    button.btn,
    button.btn-primary {
      padding: 0px 16px;
      margin: 4px;
      border-radius: $border-radius-lg;
      line-height: $btn-line-height-sm;
      &.tab-active {
        font-weight: bold;
        background: transparent;
        background-color: $primary;
        color: $text-icon-light;
        font-weight: 500;
      }
    }
  }
}

.tab-item {
  display: none;
  &.tab-active {
    display: block;
  }
}

.tab-icon {
  font-size: 1.4em;
  display: block;
}

.btn-tab {
  position: relative;
  .unread {
    position: absolute;
    right: 0;
    top: 9px;
    padding-left: 0 !important;
    padding-right: 0 !important;
    width: 16px;
    height: 16px;
    line-height: 15px;
  }
}
.pill .btn-tab .unread {
  margin-left: $spacer/2;
  position: static;
}
