.app-container {
  margin-top: 1em;
  padding-bottom: 70px;
  -webkit-transition: all 0.3s ease;
  -moz-transition: all 0.3s ease;
  transition: all 0.3s ease;
}

@media (min-width: 1220px) {
  .app-container {
    max-width: 1220px;
  }
}
.fullscreen-container {
  min-height: 100vh;
  padding-top: 2em;
  align-items: center;
  display: flex;
}

.container-mw-700 {
  max-width: 700px;
}
.aside-container {
  width: calc(100vw - 300px);
}
