.pricing {
  background: #f9f9f9;
  padding: 60px 0;
}

.pricing-container {
  gap: 1rem;
}

.pricing-panel {
  align-self: stretch;
  border: 1px solid $basic-alpha-16;
  border-radius: 12px;
  position: relative;

  .panel {
    display: flex;
    flex-direction: column;
    overflow: hidden;
    min-height: 100%;
  }
  .panel-content {
    background-color: $primary-alfa-8;
    border-radius: $border-radius-lg;
  }
  .panel-footer {
    display: flex;
    flex-direction: column;
    flex: 1;
  }
}

.pricing-features {
  list-style: none;
  .disabled {
    color: $text-icon-light-grey;
  }
  svg {
    vertical-align: bottom;
  }
  &-item {
    flex-wrap: nowrap;
    align-items: flex-start;
  }
}
.dark {
	.pricing-type {
		color: #d8d8d8;
	}
}
.pricing-type {
  color: $basic-alpha-48;
  font-size: $font-sm;
  line-height: $line-height-sm;
  text-align: center;
  font-weight: 500;
}


.featured {
  background-color: $secondary500;
  border-radius: $border-radius;
  font-size: $font-size-sm;
  line-height: $line-height-sm;
  font-weight: bold;
  padding: 6px 12px;
  position: absolute;
  top: -5px;
  right: -5px;
}

.pricing-cta {
  width: 80%;
  min-width: 170px;
  background: $success;
  margin: 0 auto;
  height: 40px;
  border-radius: 25px;
  color: #fff;
  padding: 8px 30px 10px;
  display: block;
  text-align: center;
  line-height: 40px;
  padding: 0;
  &.blue {
    background: #22354a;
    color: #fff !important;
  }

  &:hover {
    color: #fff;
    text-decoration: none;
    box-shadow: 0 1px 10px 0 rgba(0, 0, 0, 0.2);
  }
}

.pricing-icon {
  width: 75px;
  margin: 10px auto;
}
.dark {
  .featured {
    color: $body-color;
  }
  .pricing-panel {
    background-color: $bg-dark300;
    .panel-content {
      background-color: $primary-alfa-16;
      path {
        fill: $text-icon-light;
      }
    }
    .panel-footer {
      display: flex;
      flex-direction: column;
      flex: 1;
    }
  }
}

.panel-grey {
  background: #f5f8fd;
  border-radius: $border-radius;
  padding: 20px;
  margin-bottom: 20px;

  .question {
    font-weight: bold;
    margin-bottom: 5px;
  }

  .answer {
    font-size: 15px;
    opacity: 0.65;

    &:last-of-type {
      margin-bottom: 0;
    }
  }
}

.faq {
  padding: 60px 0;
}

.cta-container {
  margin-top: 60px;

  a {
    width: 300px;
  }
}
