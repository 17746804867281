@import "../variables";
.step-list {
  list-style-type: none;
  padding-left: 0;
  li {
    height: 40px;
    width: 40px;
    padding: 5px;
    border: 1px solid #2e2e2e;
    border-radius: 50%;

    span {
      display: block;
      background: #2e2e2e;
      height: 28px;
      width: 28px;
      line-height: 28px;
      text-align: center;
      border-radius: 50%;
      margin: 0 auto;
      color: #fff;
    }
  }
}

.search-list,
.List {
  overflow-x: hidden !important;
  .column {
    flex-direction: column !important;
  }
}

.overflow-visible .search-list,
.overflow-visible .List {
  overflow-x: visible !important;
}

.table-header {
  background-color: $panel-table-header-bc;
  color: $panel-table-header-color;
  border-top-right-radius: $border-radius+2;
  border-top-left-radius: $border-radius+2;
  .text-muted {
    color: $panel-table-header-color-muted !important;
  }
  font-weight: 500;
  font-size: $panel-table-header-font-size;
  line-height: $line-height-xsm;
  min-height: $panel-table-header-min-height;
}

.table-column {
  padding: 5px 12px;
}

.dark {
  .table-header {
    background-color: $bg-dark300;
  }
}
