@import '../variables';

.feature-action {
  display: flex;
  flex-direction: column;
  align-items: end;

  &__list {
    position: absolute;
    z-index: 10;
    margin-top: 6px;
    overflow: hidden;

    border-radius: 8px;
    background: #ffffff;
    box-shadow: 0px 10px 12px 0px rgba(100, 116, 139, 0.15);
  }

  &__item {
    display: flex;
    align-items: center;
    column-gap: 0.5rem;

    padding: 0.5rem 1rem;
    white-space: nowrap;

    color: #2d3443;
    font-size: $font-size-sm;
    font-style: normal;
    font-weight: 400;
    line-height: $line-height-sm;

    user-select: none;

    &_disabled {
      opacity: 0.4;
      pointer-events: none;
    }

    &:hover {
      background: $bg-light200;
    }
  }
}

.dark {
  .feature-action__list {
    background: $input-bg-dark;
    box-shadow: 0px 8px 12px 0px rgba(0, 0, 0, 0.12);
  }

  .feature-action__item {
    color: white !important;

    &:hover {
      background: $primary;
    }
  }
}
