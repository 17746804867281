
nav a {
  font-weight: 500;
  &.active {
    font-weight: bold;
  }
}
.nav-sub-link {
  padding-bottom: 10px;
  color: $body-color;
  &-inner {
    padding: 4px;
    border-radius: $border-radius;

    &:hover {
      color: $body-color;
      background-color: $primary-alfa-8;
    }
  }
  svg {
    width: 14px;
    height: 14px;
    opacity: 0.6;
    path {
      fill: $body-color;
    }
  }
  ion-icon {
    opacity: 0.6;
    font-size: $font-size-base;
  }
  &.active {
    border-bottom:2px solid $primary;
  }
}
.breadcrumb-link {
  padding: 4px 8px 4px 8px;
  border-radius: $border-radius;
  &:hover,&.hovered {
    color: $body-color;
    background-color: $primary-alfa-8;
  }
}
.dark {
  .breadcrumb-link:hover, .breadcrumb-link.hovered {
    color: $body-color-dark;
  }
  .nav-sub-link {
    &-inner {
      &:hover {
        color: $body-color-dark;
      }
    }
    svg {
      path {
        fill: $body-color-dark;
      }
    }
  }
}
.dark-mode {
  .rc-switch {
    svg {
      height: 16px;
      margin-right: 0;
      z-index: 1000;
    }
    width: $switch-dark-mode-width;
    height: $switch-dark-mode-height;
    border-radius: $border-radius;
    background-color: $switch-dark-mode-bg;
    &:after {
      width: $switcher-dark-mode-width;
      height: $switcher-dark-mode-width;
      top: 4px;
      left: 4px;
      z-index: 100;
      border-radius: $border-radius;
      box-shadow: 0px 2px 2px 0px rgba(100, 116, 139, 0.12);
    }
    &:focus {
      border: none;
      box-shadow: none;
    }
    &:hover {
      background-color: $switch-dark-mode-bg;
    }
    &-checked {
      &:after {
        left: 36px;
        background-color: $switch-dark-mode-bg-dark;
        box-shadow: 0px 2px 2px 0px rgba(0, 0, 0, 0.16);
        &:hover {
          background-color: $switcher-checked-dark-mode-bg;
        }
      }
      &:hover {
        background-color: $switcher-checked-dark-mode-bg;
      }
      background-color: $switcher-checked-dark-mode-bg;
    }
  }
}
.border-1 {
  border: 1px solid $hr-border-color;
}
.border-right {
  border-right:  1px solid $hr-border-color;
}
.collapsible__content {
  svg, ion-icon {
    text-align: center;
    width: 16px;
    height: 16px;
    color: $body-color;
    opacity: 0.6;
    path {
      fill: $body-color;
    }
  }
  ion-icon {
    font-size: $font-size-base;
  }
}
  .collapsible-title {
    line-height: 32px;
    cursor: pointer;
    border-radius: $border-radius;
    &:hover {
      color: $primary;
      background-color: $primary-alfa-8;
    }
  }
  .collapsible.active {
    .collapsible-title {
      pointer-events: none;
    }
  }
.collapsible__content a {
  padding-left: 12px;
  padding-right: 4px;
  margin-right: 10px;
  line-height: 32px;
  display: flex;
  align-items: center;
  border-radius: $border-radius;
  &.active {
    background-color: $primary-alfa-8;
  }
}
.dark {
  .collapsible__content {
    svg {
      path {
        fill: $body-color-dark;
      }
      opacity: 0.3;
    }
  }
}
