.color-block {
    width: 30px;
    height: 30px;
    border-radius: $border-radius;
    margin-right: 1em;
    display: inline-block;
    margin-bottom: 1em;
    &--small {
        width: 10px;
        height: 10px;
        border-radius: $border-radius-sm;
        margin-bottom: 0;
    }
    &--brand-primary {
        background-color: $primary;
    }
    &--brand-secondary {
        background-color: $success;
    }
    &--python {
        background-color: #3572A5;
    }
    &--javascript {
        background-color: #f1e05a;
    }
    &--c {
        background-color: #178600;
    }
    &--typescript {
        background-color: #2b7489;
    }
    &--ruby {
        background-color: #701516;
    }
    &--java {
        background-color: #b07219;
    }
}
